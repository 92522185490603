<template>
  <div class="faq">
    <div class="section" v-for="(value, index) in this.faq_data" :key="index">
      <h1>{{ this.convert_faq_group_text(value.faq_group) }}</h1>
      <ul>
        <li v-for="(item, number) in value.data" :key="number">
          <div class="title" @click="this.toggle_faq(item)">
            {{ item.faq_title }}
          </div>
          <Transition name="slide">
            <div
              class="content"
              v-if="item.opened"
              v-html="item.faq_content"
            ></div>
          </Transition>
        </li>
      </ul>
    </div>
  </div>

  <Spinner v-show="processing" />
</template>

<script>
export default {
  name: "FAQ",
  metaInfo() {
    return {
      title: this.$t("meta.title") + " :: " + this.$t("meta.faq"),
      og: {
        title: this.$t("meta.title") + " :: " + this.$t("meta.faq"),
      },
    };
  },
  data: function () {
    return {
      process_queue: [],
      faq_country: "KOR",
      faq_data: [], // [ { faq_group: "", data: [{}, ...]  }, ... ]
    };
  },
  computed: {
    // 스피너 동작 여부
    processing() {
      if (this.process_queue.length <= 0) return false;
      else return true;
    },
  },
  mounted() {
    this.faq_country = this.common.get_country_code_from_url();
    this.load_faq_data();
  },
  methods: {
    // FAQ 가져오기
    load_faq_data: function () {
      let self = this;
      self.process_queue.push(1);

      this.axios
        .post(
          process.env.VUE_APP_API_URL + "/faq/list",
          {
            faq_country: self.faq_country,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .then(function (response) {
          let data = response.data;

          self.faq_data = [];
          for (let i = 0; i < data.length; i++) {
            // 열려 있지 않게 초기 설정
            data[i].opened = false;

            // 기존 그룹이 있는 경우 추가
            let faq_group_found = false;
            for (let j = 0; j < self.faq_data.length; j++) {
              if (data[i].faq_group == self.faq_data[j].faq_group) {
                self.faq_data[j].data.push(data[i]);
                faq_group_found = true;
                break;
              }
            }

            // 기존 그룹이 없으면 새로 생성해서 추가
            if (!faq_group_found) {
              let faq_item = {
                faq_group: data[i].faq_group,
                data: [],
              };
              faq_item.data.push(data[i]);

              self.faq_data.push(faq_item);
            }
          }
        })
        .finally(function () {
          self.process_queue.pop();
        });
    }, //-- load_faq_data

    // FAQ 그룹을 텍스트로 변환
    convert_faq_group_text: function (group) {
      //AVATAR, PRODUCT, ORDER, SHIPPING, RETURN, PAYMENT, NFT, APP, ETC
      switch (group) {
        case "AVATAR":
          return this.$t("cs.faq_avatar");
        case "PRODUCT":
          return this.$t("cs.faq_product");
        case "ORDER":
          return this.$t("cs.faq_order");
        case "SHIPPING":
          return this.$t("cs.faq_shipping");
        case "RETURN":
          return this.$t("cs.faq_return");
        case "PAYMENT":
          return this.$t("cs.faq_payment");
        case "NFT":
          return this.$t("cs.faq_nft");
        case "APP":
          return this.$t("cs.faq_app");
        case "ETC":
          return this.$t("cs.faq_etc");
      }

      return "";
    }, //-- convert_faq_group_text

    toggle_faq: function (item) {
      if (typeof item.opened == "undefined") {
        item.opened = true;
        return;
      }

      item.opened = !item.opened;
    }, //-- toggle_faq
  },
};
</script>

<style scoped>
.faq {
  padding: 0;
}
.faq > .section {
  margin: 1rem 0 2rem 0;
}
.faq > .section > h1 {
  font-size: 1.6rem;
  font-weight: 700;
  padding: 0;
  margin: 0;
}
.faq > .section > ul {
  list-style-type: none;
  padding: 0;
  margin: 1rem 0 0 0;

  border-top: 1px solid #333;
}
.faq > .section > ul > li {
  border-bottom: 1px solid #dedede;
}
.faq > .section > ul > li > .title {
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1rem 0;

  cursor: pointer;
  user-select: none;
}
.faq > .section > ul > li > .content {
  font-size: 1.1rem;
  font-weight: 400;
  padding: 1rem 0;

  border-top: 1px solid #efefef;
}
</style>
